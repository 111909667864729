




































































































import { Entry } from 'contentful'
import { mapGetters } from 'vuex'
import Vue, { PropType } from 'vue'
import LegendHeader from './LegendHeader.vue'
import { formatCurrency } from '../lib/formatters'
import EnergySavingSolution from './EnergySavingSolution.vue'
import { SolutionContent, SolutionOpportunity } from '@typesCustom/contentful'
import {
  HouseScanScale,
  HouseScanResult,
  HouseScanSolutionOpportunityResult,
  SolutionOpportunityResult,
} from '@typesCustom/index'

export default Vue.extend({
  name: 'saving-measure',
  components: {
    LegendHeader,
    'saving-solution': EnergySavingSolution,
  },
  props: {
    measure: {
      type: Object as PropType<Entry<SolutionContent>>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['uiText']),
    slug(): string {
      return this.measure.fields.slug
    },
    title(): string {
      return this.measure.fields.name
    },
    // show(): boolean {
    //     console.log(this.uiText('lees-meer'));
    //     return typeof this.measure.fields.show === 'undefined' || this.measure.fields.show
    // },
    ctaUrl(): string | undefined {
      const actionUrl = this.uiText(`${this.slug}-cta`)
      if (actionUrl !== `${this.slug}-cta`) {
        return actionUrl
      }
      return this.uiText('offerte-url') !== 'offerte-url' ? this.uiText('offerte-url') : undefined
    },
    infoUrl(): string | undefined {
      const solutionUrl = this.measure.fields.infoUrl
      const questionUrl = this.uiText(this.slug)
      return questionUrl !== this.slug ? questionUrl : solutionUrl ? `https://greenhome.nl/${solutionUrl}` : undefined
    },
    background(): string {
      const { imageSquare } = this.measure.fields
      const url = imageSquare ? imageSquare.fields.file.url : 'https://bulma.io/images/placeholders/96x96.png'
      return `background-image: url(${url})`
    },
    solution(): HouseScanResult {
      return this.$store.state.solution.results.find((r: HouseScanResult) => r.id === this.measure.sys.id)
    },
    selected: {
      get(): string {
        return this.solution.selected
      },
      set(value: string) {
        this.$store.commit('UPDATE_SOLUTION', {
          selected: value,
          solutionId: this.measure.sys.id,
        })
      },
    },
    selectedSolution(): SolutionOpportunityResult | HouseScanSolutionOpportunityResult | undefined {
      return this.opportunities.find(
        (x: SolutionOpportunityResult | HouseScanSolutionOpportunityResult) => x.id === this.selected,
      )
    },
    advice(): string {
      if (!this.solution) {
        return ''
      }
      switch (this.solution.flag) {
        case 'alreadyApplied':
          return this.uiText('maatregel-al')
        case 'couldBeBetter':
          return this.uiText('kan-beter')
        case 'notPossible':
          return this.uiText('niet-mogelijk')
        case 'notViable':
          return this.uiText('niet-interessant')
        default:
          return ''
      }
    },
    infoIcon(): string {
      if (!this.solution) {
        return ''
      }
      switch (this.solution.flag) {
        case 'alreadyApplied':
          return 'check'
        case 'couldBeBetter':
          return 'plus'
        case 'notPossible':
          return 'times'
        case 'notViable':
          return 'info-circle'
        default:
          return ''
      }
    },
    infoText(): string {
      if (!this.solution) {
        return ''
      }
      const { slug } = this.measure.fields
      const { flagInfo } = this.$store.state.texts
      const { flag } = this.solution
      const flagTexts = flagInfo[slug]

      return flag && flagTexts ? flagTexts[flag] : ''
    },
    opportunities(): SolutionOpportunityResult[] | HouseScanSolutionOpportunityResult[] {
      return this.solution.opportunities.map((result: HouseScanSolutionOpportunityResult) => {
        const opportunity = this.measure.fields.solutionOpportunities.find(
          (item: Entry<SolutionOpportunity>) => item.sys.id === result.id,
        )
        return opportunity ? { ...result, ...opportunity.fields } : result
      })
    },
    classes(): { [key: string]: boolean } {
      return {
        'already-done': this.solution.flag === 'alreadyApplied',
        'not-possible': this.solution.flag === 'notPossible',
        'not-viable': this.solution.flag === 'notViable',
        open: this.open,
      }
    },
    showResults(): boolean {
      return (
        this.selectedSolution !== undefined &&
        this.solution.flag !== 'notPossible' &&
        this.solution.flag !== 'notViable' &&
        this.solution.flag !== 'alreadyApplied'
      )
    },
    showInvestment(): boolean {
      if (!this.showResults || !this.selectedSolution) {
        return false
      }
      return this.selectedSolution.results!.hasOwnProperty('investment')
    },
    investment(): string {
      if (!this.selectedSolution) {
        return ''
      }
      const { min, max }: HouseScanScale = this.selectedSolution.results!.investment
      return `${formatCurrency(min)} - ${formatCurrency(max)}`
    },
    showSaving(): boolean {
      if (!this.showResults || !this.selectedSolution) {
        return false
      }
      return this.selectedSolution.results!.saving.min > 0
    },
    saving(): string {
      if (!this.selectedSolution) {
        return ''
      }
      const { min, max }: HouseScanScale = this.selectedSolution.results!.saving
      return `${formatCurrency(min)} - ${formatCurrency(max)}`
    },
    showPayback(): boolean {
      if (!this.showResults || !this.selectedSolution) {
        return false
      }
      const { min, max } = this.selectedSolution.results!.payback
      return min < 15 && max > 0
    },
    payback(): string {
      if (!this.selectedSolution) {
        return ''
      }
      const { min, max } = this.selectedSolution.results!.payback
      return `${Math.round(min)} tot ${Math.round(max)} jaar`
    },
    showPaybackPlus(): boolean {
      if (!this.showResults || !this.selectedSolution) {
        return false
      }
      return this.selectedSolution.results!.payback.min >= 15
    },
    queryString(): string {
      return window.location.search || ''
    },
  },
  data() {
    return {
      open: false,
      recommendedSolution: undefined as SolutionOpportunityResult | HouseScanSolutionOpportunityResult | undefined,
    }
  },
  methods: {
    selectSolution(value: string) {
      this.selected = value
    },
    toggleSolutions() {
      this.open = !this.open
    },
    sendGAEvent() {
        window.ga('send', 'event', 'Button', 'Click', 'More information', `${this.infoUrl}${this.queryString}`);
    },
  },
  created() {
    this.recommendedSolution = this.selectedSolution
  },
})
