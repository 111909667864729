import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'
import SimpleAnalytics from 'simple-analytics-vue'

declare global {
  interface Window {
    // tslint:disable-next-line no-any
    dataLayer: any
    // tslint:disable-next-line no-any
    ga: any
  }
}

window.dataLayer = window.dataLayer || []

if(process.env.NODE_ENV === 'production')
{
  Vue.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== 'production' });
}

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('INIT_STORE')
    this.$store.commit('HTTP_ERROR', null)
  },
}).$mount('#app')
