








import Vue from 'vue'
import { mapGetters } from 'vuex'

const imageKey = process.env.VUE_APP_RESULTS_BACKGROUND || 'results-background'

export default Vue.extend({
  computed: {
    ...mapGetters(['uiText']),
    footerBackground(): string {
      const url = this.$store.state.texts.media[imageKey]
      return `background-image: url('${url}')`
    },
  },
})
